*{
    box-sizing: border-box;
}

body{
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #222;
}

.fullpage-enabled{
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}